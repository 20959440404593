"use client";
import { Button, Card, Container, Form, ListGroup } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { MethodData, useAxios } from '@/libs/custom-axios/CustomAxios';
import { getUsersPath, userLoginPath } from '@/libs/api/Api';
import { useEffect, useState } from 'react';
import VerifyDigits from '@/libs/verify-digits/VerifyDigits';
import { getToken } from '@/libs/token/token';
import { useRouter } from 'next/navigation';
import { getSimpleTranslation, Translate } from '@/libs/translation/Translate';
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input";
import styles from './login.module.scss';
import createToast from '@/libs/toast/CreateToast';
import { ToastBg } from '@/libs/toast/Toast';
import Link from 'next/link';
import { IUser, UserRole } from "@/types/user/user";
import { RightArrowIcon } from "@/libs/svg-icons/SVGICons";

interface ILoginData {
    phone: string;
}

const Login = () => {
    const router = useRouter();
    const [uuid, setUuid] = useState<string>();
    const {fetch: userLoginAxios, loading: userLoginLoading} = useAxios();
    const {fetch: fetchUsers, loading: loadingFetchUsers, data: users} = useAxios<Partial<IUser>[]>();

    const [screenMode, setScreenMode] = useState<'mfa' | 'login' | 'preschools'>('login');

    const methods = useForm<ILoginData>({
        shouldUseNativeValidation: false,
        mode: "onBlur",
        reValidateMode: "onChange",
        resolver: undefined,
        context: undefined,
        criteriaMode: "all",
        shouldFocusError: true,
        shouldUnregister: false,
    });

    useEffect(() => {
        const token = getToken();
        if (token) {
            router.push("/dashboard");
        }
    }, []);

    const verifyDigitsErrorHandler = () => {
        setUuid('');
        setScreenMode('login');
        methods.reset();
    }

    const loginUser = (data: any) => {        
        !userLoginLoading && userLoginAxios(userLoginPath, {
            method: MethodData.POST,
            body: data
        }).then((json: { uuid: string }) => {
            setScreenMode('mfa');
            setUuid(json.uuid);
        }).catch(err => {
            console.log(err);
            if (err.error.statusCode === 404) {
                createToast({
                    bg: ToastBg.Danger,
                    content: getSimpleTranslation("userNotFound")
                });
            }
        })
    }

    const userSelectHandler = (userId: number) => {
        const phone = methods.getValues('phone');
        loginUser({phone, userId});
    }

    const getPreschoolTitle = (preschoolName: string, logoUrl: string) => {
        return (
            <div className="preschool-title">
                <img src={logoUrl} width={24} alt="Logo"/>
                {preschoolName}
            </div>
        )
    };

    const usersReceivedHandler = (users: Partial<IUser>[], formData: any) => {
        const eligibleUsers = users.filter(u => [UserRole.Admin, UserRole.SuperAdmin].includes(u.role as UserRole));
        if (!eligibleUsers.length) {
            createToast({
                bg: ToastBg.Danger,
                content: getSimpleTranslation("userNotFound")
            });
        } else if (eligibleUsers.length === 1) {
            loginUser({...formData, userId: users[0].id});
        } else {
            setScreenMode('preschools');
        }
    }

    const getCurrentScreenView = () => {
        switch (screenMode) {
            case "login":
                return (
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><Translate keyword={"phone"}/></Form.Label>
                            <Controller
                                name="phone"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                <PhoneInput
                                    value={value}
                                    className={'form-control phone-input'}
                                    onChange={onChange}
                                    defaultCountry="AM"
                                    id="phone-input"
                                />
                                )}
                            />
                        </Form.Group>
                        <div className="d-grid gap-2">
                            <Button variant="primary" type="submit" disabled={userLoginLoading}>
                                <Translate keyword={"login"}/> {userLoginLoading ? "…" : ""}
                            </Button>
                        </div>
                    </Form>
                );
            case "mfa":
                return (
                    uuid && <VerifyDigits params={{uuid}} onError={verifyDigitsErrorHandler}/>
                )
            case 'preschools':
                return (
                    <>
                        <p className="preschoolsListTitle">{getSimpleTranslation("selectPreschool")}</p>
                        <ListGroup>
                            {users?.map(user => (
                                <ListGroup.Item onClick={() => userSelectHandler(user.id as number)}>
                                    <a className="login-option-item">
                                        {getPreschoolTitle(user.preschool?.name as string, user.preschool?.logoUrl as string)}
                                        <RightArrowIcon />
                                    </a>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </>
                )
        }
    }

    return (
        <Container className={styles.container}>
            <div><img src={'/name-logo.png'} width={200} alt="Logo"/></div>
            <Card>
                <Card.Body>
                    {getCurrentScreenView()}
                </Card.Body>
            </Card>
            <div className='text-center' >
            <Link className={"link"} href={`/terms/`}>
                <strong>{getSimpleTranslation("terms")}</strong>
            </Link>
            <Link className={"link"} href={`/privacy-policy/`}>
                <strong>{getSimpleTranslation("privacy-policy")}</strong>
            </Link>
            </div>
            
        </Container>
    );

    function onSubmit(data: ILoginData) {
        !loadingFetchUsers && fetchUsers(getUsersPath, {
            method: MethodData.POST,
            body: data,
        }).then(users => {
            usersReceivedHandler(users, data);
        })
    }
};
export default Login;
