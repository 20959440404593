import i18next from "i18next";
import { useSearchParams } from "next/navigation";
import { getI18n, useTranslation } from "react-i18next";

interface ITranslationProps {
    keyword: string;
    hasHtml?: boolean;
    replaceObj?: { [key: string]: string };
}

export const Translate: React.FC<ITranslationProps> = ({
                                                           keyword,
                                                           replaceObj = {},
                                                           hasHtml = false
                                                       }): JSX.Element => {
    const {t} = useTranslation();

    return (
        <>
            {
                hasHtml ? (
                    <em style={{fontStyle: "normal"}}
                        dangerouslySetInnerHTML={{__html: t(keyword, {replace: replaceObj})}}/>
                ) : t(keyword, {replace: replaceObj})
            }
        </>
    );
};

export function getSimpleTranslation(keyword: string, replaceObj = {}, lng = 'hy') {
    return i18next.t(keyword, {replace: replaceObj, lng});
}

export function getCurrentLanguage() {
    return getI18n().language;
}
