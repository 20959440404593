import React, { Dispatch } from "react";
import { IUserActionProps } from "@/reducer/user/UserReducer";
import { IUser } from "@/types/user/user";

interface IUserContext {
    user?: IUser;
    dispatch?: Dispatch<IUserActionProps>;
}

export const UserContext: React.Context<IUserContext> = React.createContext({});
