import { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as _ from "lodash";
import { forgetToken, getToken, updateAccessToken } from "@/libs/token/token";
import { exchangeByRefreshTokenPath } from "../api/Api";

export enum MethodData {
    POST = "post",
    GET = "get",
    DELETE = "delete",
    PUT = "put"
}

interface IAxiosState<T> {
    loading: boolean;
    data: T | null;
    error: any;
}

interface IOptions {
    method?: MethodData;
    headers?: any;
    body?: any;
    queryParams?: any;
    responseType?: any;
    signal?: AbortSignal;
}

export const useAxios = <T = any, >(url?: string, options?: IOptions, resetDataOnRequest = false) => {
    const [fullData, setFullData] = useState<IAxiosState<T>>({
        loading: !!url,
        data: null,
        error: null
    });
    const isFirstLoading: React.MutableRefObject<any> = useRef(true);
    const abortControllerRef: React.MutableRefObject<any> = useRef();

    useEffect(() => {
        options?.method === MethodData.GET && (abortControllerRef.current = {
            isPrevAborted: false,
            newSignal: new AbortController()
        });

        url && fetch(url, options, resetDataOnRequest);
    }, [url, JSON.stringify(options?.queryParams)]);

    const fetch = (url: string, options: IOptions = {}, resetDataOnRequest = false) => {
        if (options?.method === MethodData.GET) {
            abortControllerRef.current ? (abortControllerRef.current.newSignal.signal.onabort = () => {
                abortControllerRef.current.isPrevAborted = true;
                abortControllerRef.current.newSignal = new AbortController();
            }) : (
                abortControllerRef.current = {
                    isPrevAborted: false,
                    newSignal: new AbortController()
                });
            fullData.loading && !isFirstLoading.current && abortControllerRef.current?.newSignal.abort();
            isFirstLoading.current = false;
        }
        setFullData({
            ...fullData,
            ...(resetDataOnRequest ? {} : {data: null}),
            error: null,
            loading: true
        });

        const requestCustomAxios = async(): Promise<any> => {
            let isRefreshTokenUsed = false;
            try {
                const customAxiosResponse = await customAxios(url, {...options, signal: abortControllerRef.current?.newSignal.signal});
                setFullData(prevState => ({
                    ...prevState,
                    data: customAxiosResponse,
                    loading: false,
                    error: null
                }));
                return customAxiosResponse;
            } catch (error: any) {
                if (!isRefreshTokenUsed && error.response?.status === 401) {
                    const refreshToken = getToken('refreshToken');
                    const jti = getToken('jti');
                    if (!refreshToken) {
                        forgetToken();
                        window.location.reload();
                        return;
                    }
                    // Getting accessToken by refreshToken
                    try {
                        const newAccessToken = await customAxios(exchangeByRefreshTokenPath, {
                            body: { refreshToken, jti },
                            method: MethodData.POST
                        });
                        if (newAccessToken) {
                            isRefreshTokenUsed = true;
                            updateAccessToken(newAccessToken);
                            return requestCustomAxios();
                        }
                        
                    } catch (error: any) {
                        forgetToken();
                        window.location.reload();
                    }
                }
                error && setFullData({
                    data: null,
                    loading: false,
                    error: error.response?.data
                });

                   // showErrorToast && createToast({
                    //     bg: ToastBg.Warning,
                    //     heading: 'Error',
                    //     content: error.response?.data?.message || 'Something went wrong.'
                    // });
                throw {error: error.response?.data};
            }
        };

        return requestCustomAxios();
    };

    return {loading: fullData.loading, error: fullData.error, fetch, data: fullData.data};
};

export const customAxios = (url: string, options: IOptions = {}) => {
    const requestOptions = {method: MethodData.GET, ...options};
    const params: any = new URLSearchParams();
    const token = getToken();

    for (const key in requestOptions.queryParams) {
        if (requestOptions.queryParams.hasOwnProperty(key)) {
            if (_.isArray(requestOptions.queryParams[key])) {
                requestOptions.queryParams[key].forEach((v: string) => {
                    params.append(key, v);
                });
            } else {
                params.append(key, requestOptions.queryParams[key]);
            }
        }
    }

    return axios({
        method: requestOptions.method,
        headers: {
            ...(token ? {"Authorization": `Bearer ${token}`} : {}),
            ...(requestOptions.headers || {"Content-Type": "application/json"})
        },
        url,
        responseType: requestOptions?.responseType || "json",
        params,
        signal: requestOptions.signal,

        data: requestOptions.body || {}
    })
        .then(response => response.data);
};
