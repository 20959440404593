import { IUser } from '@/types/user/user';

export enum UserDispatchProps {
    SET_USER = 'set_user',
    UPDATE_USER = 'update_user',
}

export const setUser = (data: IUser) => {
    return {
        type: UserDispatchProps.SET_USER,
        data
    };
};
