const apiUrl = `/api`;

/*--------------------------------------------------------------------------Auth---------------------------------------------------------------*/
export const userLoginPath = `${apiUrl}/auth/login`;
export const verifyLoginPath = `${apiUrl}/auth/mfa`;
export const exchangeByRefreshTokenPath = `${apiUrl}/auth/exchange`;
export const getMePath = `${apiUrl}/auth/me`;
export const getUsersPath = `${apiUrl}/auth/users`;

/*---------------------------------------------------------------------------------------------------------------------------------------------*/

/*------------------------------------------------------------------------File upload----------------------------------------------------------*/
export const fileUploadPath = `${apiUrl}/file/upload`;
/*---------------------------------------------------------------------------------------------------------------------------------------------*/

/*-------------------------------------------------------------------------Preschool-----------------------------------------------------------*/
export const preschoolsPath = `${apiUrl}/preschools`;
export const getPreschoolByIdPath = (id: string | number) => `${apiUrl}/preschools/${id}`;
/*---------------------------------------------------------------------------------------------------------------------------------------------*/

/*---------------------------------------------------------------------------Groups------------------------------------------------------------*/
export const preschoolGroupsPath = `${apiUrl}/groups`;
export const getPreschoolGroupByIdPath = (id: string | number) => `${apiUrl}/groups/${id}`;
export const getGroupTeachersByGroupIdPath = (id: string | number) => `${apiUrl}/groups/${id}/teachers`;
/*---------------------------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------------------------Food Type----------------------------------------------------------*/
export const foodTypesPath = `${apiUrl}/food-types`;
export const getFoodTypeByIdPath = (id: string | number) => `${apiUrl}/food-types/${id}`;
/*---------------------------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------------------------Meal Type----------------------------------------------------------*/
export const mealTypesPath = `${apiUrl}/meal-types`;
export const getMealTypeByIdPath = (id: string | number) => `${apiUrl}/meal-types/${id}`;
/*---------------------------------------------------------------------------------------------------------------------------------------------*/

/*--------------------------------------------------------------------------Menu Type----------------------------------------------------------*/
export const menusPath = `${apiUrl}/menus`;
export const getMenuByIdPath = (id: string | number) => `${apiUrl}/menus/${id}`;
export const duplicateMenuByIdPath = (id: string | number) => `${apiUrl}/menus/${id}/duplicate`;
export const getMenuItemsByIdPath = (id: string | number) => `${apiUrl}/menus/${id}/items`;
export const deleteMenuItemsByIdPath = (id: string | number, menuItemId: string | number) => `${apiUrl}/menus/${id}/items/${menuItemId}`;
/*---------------------------------------------------------------------------------------------------------------------------------------------*/

/*---------------------------------------------------------------------------Teachers----------------------------------------------------------*/
export const membersPath = `${apiUrl}/members`;
export const getMemberByIdPath = (id: string | number) => `${apiUrl}/members/${id}`;
export const archiveMemberByIdPath = (id: string | number) => `${apiUrl}/members/${id}/archive`;
export const getArchiveMembersPath = `${apiUrl}/members/archived`;
export const unArchivedMemberByIdPath = (id: string | number) => `${apiUrl}/members/${id}/unarchived`;
/*---------------------------------------------------------------------------------------------------------------------------------------------*/

/*---------------------------------------------------------------------------Children----------------------------------------------------------*/
export const childrenPath = `${apiUrl}/children`;
export const getChildByIdPath = (id: string | number) => `${apiUrl}/children/${id}`;
export const getChildDetailsByIdPath = (id: string | number) => `${apiUrl}/children/${id}/details`;
export const approveChildByIdPath = (id: string | number) => `${apiUrl}/children/${id}/approve`;
export const getChildEventsByIdPath = (id: string | number) => `${apiUrl}/children/${id}/child-events`;
export const getChildParentsByChildIdPath = (id: string | number) => `${apiUrl}/children/${id}/parents`;
export const archiveChildPath = (id: string | number) => `${apiUrl}/children/${id}/archive`;
export const getArchivedChildrenPath = `${apiUrl}/children/archived`;
export const unArchivedChildByIdPath = (id: string | number) => `${apiUrl}/children/${id}/unarchived`;
/*---------------------------------------------------------------------------------------------------------------------------------------------*/

/*---------------------------------------------------------------------------Activities--------------------------------------------------------*/
export const activitiesPath = `${apiUrl}/activities`;
export const getActivityByIdPath = (id: string | number) => `${apiUrl}/activities/${id}`;
/*---------------------------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------Dashboard--------------------------------------------------------*/
export const dashboardPath = `${apiUrl}/dashboard`;
export const dashboardWeeklyPath = `${apiUrl}/dashboard/weekly`;
export const dashboardLiveDetailsPath = `${apiUrl}/dashboard/live-details`;
/*---------------------------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------Weekly menu------------------------------------------------------*/
export const weeksPath = `${apiUrl}/weeks`;
export const currentWeekPath = `${apiUrl}/weeks/current`;
export const weeksMenuPath = `${apiUrl}/weeks/menu`;
export const getWeekByIdPath = (id: string | number) => `${apiUrl}/weeks/${id}`;
export const getWeekMenuByIdPath = (id: string | number) => `${apiUrl}/weeks/menu/${id}`;
/*---------------------------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------Child events------------------------------------------------------*/
export const childEventsTimelinePath = (id: number | string) => `${apiUrl}/child-events/${id}/timeline`;
export const getChildEventsPath = `${apiUrl}/child-events`;
/*---------------------------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------Check control----------------------------------------------------*/
export const checkControlPath = `${apiUrl}/check-control`;
/*---------------------------------------------------------------------------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------Syllabus items----------------------------------------------------*/
export const postCreateSyllabusItemsPath = `${apiUrl}/syllabus-items`;
export const postDuplicateWeekSyllabusPath = `${apiUrl}/syllabus-items/duplicate-week`;
export const syllabusItemsByIdPath = (id: number | string) => `${apiUrl}/syllabus-items/${id}`;
export const getSyllabusItemsByWeekPath = `${apiUrl}/syllabus-items/by-week`;
/*---------------------------------------------------------------------------------------------------------------------------------------------*/
