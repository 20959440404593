import { useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { MethodData, useAxios } from '@/libs/custom-axios/CustomAxios';
import { UserContext } from '@/contexts/user/UserContext';
import { getMePath, verifyLoginPath } from '@/libs/api/Api';
import { IToken, forgetToken, keepToken } from '@/libs/token/token';
import { setUser } from '@/reducer/user/UserReducerDispatch';
import { useRouter } from 'next/navigation';
import { Translate, getSimpleTranslation } from '@/libs/translation/Translate';
import { UserRole } from "@/types/user/user";
import createToast from "@/libs/toast/CreateToast";
import { ToastBg } from "../toast/Toast";

interface IVerifyData {
    codes: { [key: string]: string };
}

interface IVerifyDigitsProps {
    verifiedNumbersCount?: number;
    params?: { [key: string]: string };
    onError?: () => void
}

const VerifyDigits: React.FC<IVerifyDigitsProps> = ({verifiedNumbersCount = 5, params = {}, onError}) => {
    const router = useRouter();
    const {dispatch} = useContext(UserContext);
    const {fetch: getMe} = useAxios();
    const {
        setValue,
        setFocus,
        handleSubmit,
        register
    } = useForm<IVerifyData>({
        shouldUseNativeValidation: false,
        mode: "onBlur",
        reValidateMode: "onChange",
        resolver: undefined,
        context: undefined,
        criteriaMode: "all",
        shouldFocusError: true,
        shouldUnregister: false
    });
    const {fetch: verifyAxios, loading: verifyLoading} = useAxios();

    const drawInputs = useMemo(() => {
        const inputsArr = [];
        for (let i = 1; i <= verifiedNumbersCount; i++) {
            const name: any = `codes.code-part-${i}`;
            inputsArr.push((
                <Form.Control key={name}
                              style={{minWidth: 40, alignItems: "center", textAlign: "center"}}
                              {...register(name)}
                              onKeyUp={({nativeEvent}) => {
                                  if (nativeEvent.key === "Backspace") {
                                      setValue(`codes.code-part-${i}`, "");
                                      setFocus(`codes.code-part-${i - 1}`);
                                  } else if (!/^\d+$/.test(nativeEvent.key.toString())) {
                                      nativeEvent.key !== "Enter" && setValue(name, "");
                                  } else {
                                      setValue(name, nativeEvent.key);
                                      setFocus(`codes.code-part-${i + 1}`);
                                  }
                              }}/>
            ));
        }

        return inputsArr;
    }, []);

    useEffect(() => {
        setFocus(`codes.code-part-1`);
    }, []);

    return (
        <Form className={'d-flex flex-column gap-3'} onSubmit={handleSubmit(onSubmit)}>
            <Form.Label><Translate keyword={"smsVerifyText"}/></Form.Label>
            <div style={{display: "flex", gap: 10}}>
                {drawInputs}
            </div>
            <Button variant="primary" type={"submit"}>
                <Translate keyword={"verify"}/>
            </Button>
        </Form>
    );

    function onSubmit(data: IVerifyData) {
        !verifyLoading && verifyAxios(verifyLoginPath, {
            method: MethodData.POST,
            body: {
                code: Object.values(data.codes).join(""),
                ...params
            }
        }).then((json: IToken) => {
            keepToken(json);
            getMe(getMePath, {method: MethodData.GET}).then((ud: any) => {
                if ([UserRole.Admin, UserRole.SuperAdmin].includes(ud.role)) {
                    dispatch?.(setUser(ud));
                    router.push("/dashboard");
                } else {
                    onError?.();
                    forgetToken();
                    createToast({
                        bg: ToastBg.Danger,
                        content: getSimpleTranslation("noPermissonForAdminPortal")
                    });
                }
            });
        });
    }
};
export default VerifyDigits;
