import { IPreschool } from "@/types/preschool/preschool";
import { ICheckControl, IChild } from "@/types/child/child";
import { ITeachersOnGroups } from "@/types/group/group";

export enum Gender {
    MALE = "Male",
    FEMALE = "Female",
}
export enum Status {
    ACTIVE = "Active",
    ARCHIVED = "Archived",
}

export enum UserRole {
    SuperAdmin = "SuperAdmin",
    Admin = "Admin",
    Teacher = "Teacher",
    Parent = "Parent",
    Staff = "Staff"
}

export interface IUser {
    id: number;
    preschoolId?: number;
    firstName: string;
    lastName: string;
    enFirstName?: string;
    enLastName?: string;
    gender: Gender;
    phone: number;
    email: string;
    profileImagePath: string;
    address: string;
    birthDate: string;
    employmentPlace: string;
    position?: string;
    role: UserRole;
    status: Status;
    children: IChild[];
    groupRelations: ITeachersOnGroups[];
    preschool: IPreschool;
    checkControls: ICheckControl[];
    createdAt: string;
    hidePhoneForOtherParents: boolean
}
